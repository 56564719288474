
import {Vue, Component, Ref, Prop, Watch} from 'vue-property-decorator';
import HtmlBlockEditor from "@/pages/manage/booklet-templates/components/editors/html-block-editor.vue";
import BookletBloksDialog from "@/pages/manage/booklet-templates/components/booklet-bloks-dialog.vue";
import BlockController from "@/pages/manage/booklet-templates/components/block-controller.vue";
import {ListDataSource} from "@/data/List/ListDataSource";
import draggable from "vuedraggable";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
import bloks from "@/pages/manage/booklet-templates/components/bloks.json";
import {mdiPlus} from "@mdi/js";
import {Filter} from "@/cms-services/filter";
import BlockBlock from "./blocks/block.vue"
import GalleryBlock from "./blocks/gallery.vue"
import HtmlBlock from "./blocks/html.vue"
import HtmlImageBlock from "./blocks/html-image.vue"

@Component({
  components: {
    BlockController, BookletBloksDialog,
    HtmlBlockEditor, draggable,
    BlockBlock, GalleryBlock, HtmlBlock,HtmlImageBlock
  }
})
export default class BookletBloks extends Vue {
  @Prop() dataSource!: ObjectDataSource
  @Prop() pageDataSource!: ListDataSource
  @Prop() pageId!: any
  @Ref("canvasParent") canvasParent!: HTMLDivElement;
  @Ref("htmlEditor") htmlEditor!: HtmlBlockEditor
  @Ref("bookletBloks") bookletBloks!: any
  @Ref("canvas") canvas!: HTMLDivElement
  $message: any
  blockDataSource: ListDataSource = new ListDataSource({
    className: "BookletTemplateBlock",
    config: {},
  })
  galleryBlockDataSource: ListDataSource = new ListDataSource({
    className: "BookletTemplateGalleryBlock",
    config: {},
  })
  htmlBlockDataSource: ListDataSource = new ListDataSource({
    className: "BookletTemplateHtmlBlock",
    config: {},
  })
  htmlImageBlockDataSource: ListDataSource = new ListDataSource({
    className: "BookletTemplateHtmlImageBlock",
    config: {},
  })
  icons: any = {plus: mdiPlus};

  getBlock = (item: any) => bloks.find(b => b.name == item.name)?.component + '-block';

  get page() {
    return this.pageDataSource.items.find(i => i.id == this.pageId);
  }

  get width() {
    return this.page?.width ?? "848px";
  }

  get height() {
    return this.page?.width ?? "1200px";
  }

  get scale() {

    if (!this.canvas) return 1;
    return this.canvas.offsetWidth / 848
  }

  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  }


  async mounted() {
    await this.onPageChanged(this.pageId);
    await this.$nextTick();
    this.onWindowResize();
  }

  onWindowResize() {
    this.canvas.style.transform = `scale(${this.canvasParent.offsetWidth / 848})`;
  }


  @Watch("pageId")
  async onPageChanged(value: number) {
    const filter = JSON.stringify([new Filter("pageId", value)])
    this.blockDataSource.className = "BookletTemplateBlock";
    this.blockDataSource.config.filter = filter;
    this.galleryBlockDataSource.config.filter = filter;
    this.htmlBlockDataSource.config.filter = filter;
    this.htmlImageBlockDataSource.config.filter = filter;

    await Promise.all([
      this.blockDataSource.get(),
      this.galleryBlockDataSource.get(),
      this.htmlBlockDataSource.get(),
      this.htmlImageBlockDataSource.get()
    ])

    this.blockDataSource.items = [
      ...this.blockDataSource.items,
      ...this.galleryBlockDataSource.items,
      ...this.htmlBlockDataSource.items,
      ... this.htmlImageBlockDataSource.items
    ].sort(
      (p: any, n: any) => p.priority - n.priority
    )
  }


  async add(addToEnd: boolean, index?: number | undefined) {
    const model = await this.bookletBloks.select();
    if (!model || !this.blockDataSource) return;
    this.blockDataSource.className = model.className;
    const data = {
      bookletTemplateId: this.dataSource.id,
      bookletTemplatePageId: this.pageId,
      instanceStateId: 4,
      caption: model.caption,
      description: model.description,
      name: model.name,
      razorTemplate: model.razorTemplate
    };
    await this.blockDataSource.add(data, addToEnd, index)
  }

  async remove(blockId: number) {
    await this.blockDataSource.remove(blockId);
    this.$message("Блок успешно удалён");
  }
}
