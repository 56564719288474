
import {Vue, Component, Prop} from 'vue-property-decorator';
import bloks from "@/pages/manage/booklet-templates/components/bloks.json";
import BlockController from "@/pages/manage/booklet-templates/components/block-controller.vue";

@Component({
  components: {BlockController}
})
export default class block extends Vue {
  @Prop() block!:any
  get blockInfo(){
    return  bloks.find(b => b.name == this.block.name)
  }
}
