
import {Vue, Component, Prop, Ref} from 'vue-property-decorator';
import bloks from "@/pages/manage/booklet-templates/components/bloks.json";
import BlockController from "@/pages/manage/booklet-templates/components/block-controller.vue";
import HtmlBlockEditor from "@/pages/manage/booklet-templates/components/editors/html-block-editor.vue";
import BookletTemplateHtmlImage from "@/pages/manage/booklet-templates/components/booklet-template-html-image.vue";
import {ListDataSource} from "@/data/List/ListDataSource";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";

@Component({
  components: {BookletTemplateHtmlImage, HtmlBlockEditor, BlockController}
})
export default class block extends Vue {
  @Ref("editor") editor!:BookletTemplateHtmlImage
  @Prop() block!:any
  $message:any
  get caption(){
    return  bloks.find(b => b.name == this.block.name)?.caption
  }

  get imageCol(){
    return this.dataSource.model?.image?.grid ?? undefined;
  }


  get htmlCol(){
    return this.imageCol ? 12 - this.imageCol : undefined
  }

  dataSource:ObjectDataSource = new ObjectDataSource({
    className:"BookletTemplateHtmlImageBlock",
    config:{},
    id:this.block.id,
    model:this.block
  })

  get styles(){
    const width =  this.block.image?.width;
    const height =  this.block.image?.height;
    let size = "";
    if(width) size += `width:${width};`
    if(height) size += `height:${height};`
    if(width && height) size += "object-fit:cover; object-position:center;"
    return size;
  }


  async edit() {
    await this.editor.open();
    this.$message("Данные успешно обновлены");
  }
}
