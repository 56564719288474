
import {Vue, Component} from 'vue-property-decorator';
import bloks from "./bloks.json"
@Component({})
export default class BookletBloksDialog extends Vue {
  dialog = false
  resolve!:Function
  bloks = bloks
  public select(){
    this.dialog = true;
    return new Promise(r => this.resolve=r);
  }

  close(model:any){
    this.dialog = false;
    this.resolve(model)
  }
}
