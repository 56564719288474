
import {Vue, Component, Prop} from 'vue-property-decorator';
import Ace from '@/components/helpers/ace.vue';
import UiHtmlEditor from '@/components/helpers/ui-html-editor.vue';
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";

@Component({
  components: { Ace, UiHtmlEditor },
})
export default class HmtlInlineEditor extends Vue {
  dialog: boolean = false;
  mode: any = null;
  modeList: any = ['Редактор', 'HTML', 'Просмотр'];
  save: any;
  loading: boolean = false;
  cancelChanges: any;
  resolve!:Function
  dataSource:ObjectDataSource = new ObjectDataSource({
    className:"BookletTemplateHtmlBlock",
    config:{},
    id:0
  })
  $message: any;

  async saveChanges() {
    this.loading = true;
    await this.dataSource.updateField({
      fieldName:"html",
      fieldValue: this.dataSource.model.html
    });
    this.loading = false;
    this.resolve();
    this.$message("Успешно сохранено");
    this.dialog = false;
  }

  edit(block:any){
    this.dataSource.model = block;
    this.dataSource.id = block.id;
    this.dialog = true;
    return new Promise(r => this.resolve = r);
  }


  close(){
    this.resolve();
    this.dialog = false;
  }
}
