
import { Vue, Component, Prop } from "vue-property-decorator";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
import blocks from "./bloks.json"
@Component({
  name: "content-block-controller",
})
export default class BlockController extends Vue {
  @Prop() block!: any;
  @Prop() preview!: boolean;
  @Prop({default:true}) canEdit!: boolean;
  @Prop({default:true}) allowMove!: boolean;
  @Prop({default:false}) allowEdit!:boolean;
  @Prop({default:false}) allowConfig!:boolean;

  dataSource: ObjectDataSource = new ObjectDataSource({
    className:this.block.className,
    config:{},
    model:this.block,
    id:this.block?.id
  });

  editMode: boolean = false;
  loaded: boolean = false;
  $message: any;
  $confirm: any;

  get isVisible() {
    return this.block?.instanceStateId == 4;
  }

  get blockInfo(){
    return blocks.find(b => b.name == this.block.name)
  }
  async changeState() {
    await this.dataSource.updateField({
      fieldName: "instanceStateId",
      fieldValue: this.block?.instanceStateId == 4 ? 2 : 4,
    })
    this.$message(`Данные успешно сохранены`);
  }

  chooseBlock(addToEnd: boolean) {
    this.$emit("insert", addToEnd)
  }

  async remove() {
    const confirm = await this.$confirm(
      "Вы действительно хотите удалить данный блок со всеми его содержимыми??",
    );
    if (!confirm) return;
    this.$emit("remove")
  }
}
